import { Interest, InterestType } from '../util/types';
import { interestUrl } from './data';

export const interests: Interest[] = [
  {
    title: InterestType.ClassicalMusic,
    intro: `"Music is art decorating time."`,
    description: `I started playing violin when I was 6 years old.
    Despite the lackness of practice, I'm still playing it anyways.
    My favourite composers are Debussy, Sibelius and Tchaikovsky. 
    I play in IC Sinfonietta and IC String Ensemble. 
    `,
    imgLink: `${interestUrl}/music-score.jpg`,
    detailLink: `${interestUrl}/classical-music`,
    photos: [
      '../interests/classicalMusic/classicalMusic1.jpeg',
      '../interests/classicalMusic/classicalMusic2.webp',
      '../interests/classicalMusic/classicalMusic3.jpeg',
      '../interests/classicalMusic/classicalMusic4.webp'
    ],
  },
  {
    title: InterestType.Cats,
    intro: `As you might have found out, I love cats.`,
    description: `I've had many cats in my life, and I used to have 7 cats at the same time.
    I only have one now, her name is Chloe.
    I wish she could be happier and healthier than any other cats I had before. `,
    imgLink: `${interestUrl}/cat.jpg`,
    detailLink: `${interestUrl}/cats`,
    photos: [
      '../interests/cats/cat1.jpg',
      '../interests/cats/cat2.jpg',
      '../interests/cats/cat3.jpg',
      '../interests/cats/cat4.jpg',
      '../interests/cats/cat5.jpg',
      '../interests/cats/cat6.jpg',
      '../interests/cats/cat7.jpg',
      '../interests/cats/cat8.jpg',
      '../interests/cats/cat9.jpg',
      '../interests/cats/cat10.jpg',
      '../interests/cats/cat11.jpg',
      '../interests/cats/cat12.jpg',
      '../interests/cats/cat13.jpg',
      '../interests/cats/cat14.jpg',
      '../interests/cats/cat15.jpg',
      '../interests/cats/cat16.jpg',
      '../interests/cats/cat17.jpg',
      '../interests/cats/cat18.jpg',
      '../interests/cats/cat19.jpg',
      '../interests/cats/cat20.jpg',
      '../interests/cats/cat21.jpg',
      '../interests/cats/cat22.jpg',
      '../interests/cats/cat23.jpg',
      '../interests/cats/cat24.jpg',
      '../interests/cats/cat25.jpg',
      '../interests/cats/cat26.jpg',
      '../interests/cats/cat27.jpg',
      '../interests/cats/cat28.jpg',
      '../interests/cats/cat29.jpg',
      '../interests/cats/cat30.jpg',
      '../interests/cats/cat31.jpg',
      '../interests/cats/cat32.jpg',
    ],
  },
  {
    title: InterestType.Musicals,
    intro: `"Measure your life in love."`,
    description: `My favourite musical is Les Miserables. 
    I also love Phantom of the Opera, Hamilton, Cats, Elisabeth, 
    Rent, Mozart!, Mozart l'opéra rock, Le Rouge et le Noir and so on...`,
    imgLink: `${interestUrl}/musical.jpg`,
    detailLink: `${interestUrl}/musicals`,
    photos: [
      '../interests/musicals/musical1.jpg',
      '../interests/musicals/musical2.jpg',
      '../interests/musicals/musical3.jpg',
      '../interests/musicals/musical4.jpg',
      '../interests/musicals/musical5.jpg',
      '../interests/musicals/musical6.jpg',
      '../interests/musicals/musical7.jpg',
      '../interests/musicals/musical8.jpg',
      '../interests/musicals/musical9.jpg',
      '../interests/musicals/musical10.jpg',
      '../interests/musicals/musical11.jpg',
      '../interests/musicals/musical12.jpg',
      '../interests/musicals/musical13.jpg',
    ],
  },
  {
    title: InterestType.Poems,
    intro: `『曾批给雨支风券，累上留云借月章。』`,
    description: `"Shall I compare thee to a summer's day?"`,
    imgLink: `${interestUrl}/poem.jpg`,
    detailLink: `${interestUrl}/poems`,
    photos: [
      '../interests/poem/poem1.jpeg',
      '../interests/poem/poem2.jpeg',
      '../interests/poem/poem3.jpeg',
      '../interests/poem/poem4.jpeg',
    ],
  },
  {
    title: InterestType.Traveling,
    intro: `Read ten thousand books, travel ten thousand miles.`,
    description: `I love traveling.`,
    imgLink: `${interestUrl}/traveling.jpg`,
    detailLink: `${interestUrl}/traveling`,
    photos: [
      '../interests/traveling/traveling1.jpg',
      '../interests/traveling/traveling2.jpg',
      '../interests/traveling/traveling3.jpg',
      '../interests/traveling/traveling4.jpg',
      '../interests/traveling/traveling5.jpg',
      '../interests/traveling/traveling6.jpg',
      '../interests/traveling/traveling7.jpg',
      '../interests/traveling/traveling8.jpg',
      '../interests/traveling/traveling9.jpg',
      '../interests/traveling/traveling10.jpg',
      '../interests/traveling/traveling11.jpg',
      '../interests/traveling/traveling12.jpg',
      '../interests/traveling/traveling13.jpg',
      '../interests/traveling/traveling14.jpg',
      '../interests/traveling/traveling15.jpg',
      '../interests/traveling/traveling16.jpg',
      '../interests/traveling/traveling17.jpg',
      '../interests/traveling/traveling18.jpg',
      '../interests/traveling/traveling19.jpg',
      '../interests/traveling/traveling20.jpg',
    ],
  },
  {
    title: InterestType.Food,
    intro: `Live for love and love for food.`,
    description: ``,
    imgLink: `${interestUrl}/food.jpg`,
    detailLink: `${interestUrl}/food`,
    photos: [
      '../interests/food/food1.jpg',
      '../interests/food/food2.jpg',
      '../interests/food/food3.jpg',
      '../interests/food/food4.jpg',
      '../interests/food/food5.jpg',
      '../interests/food/food6.jpg',
      '../interests/food/food7.jpg',
      '../interests/food/food8.jpg',
    ],
  },
];
